import React, { useState, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import BigTitle from "../../Components/BigTitle";
import Meta from "../../Components/Meta";
import * as dataSupport from "../../Data/TechnicalSupport.json";
import "./TechnicalSupport.scss";
import HeaderAccount from "../../Components/HeaderAccount/HeaderAccount";
import Breadcrumb from "../../Components/Breadcrumb";
import SideBar from "../../Components/SideBar/SideBar";
import Footer from "../../Components/Footer";
import IconButton from "@mui/material/IconButton";
import axios from 'axios';

export const TechnicalSupport = (props) => {
  const { lang } = useParams();
  const [device, setDevice] = useState({
    activation: "0",
    code: "",
    copy: "0",
    date_purchase: "",
    device_id: "",
    file: "",
    is_validate: "0",
    name: "PHYSIO",
    name_distributer: "",
    serial_num: "",
    user_id: "",
    warranty_date: "0000-00-00",
    interface: "",
    core: ""
  }
  );
  const [data, setData] = useState(dataSupport.default[lang]);
  const [meta, setMeta] = useState({
    title: dataSupport.default[lang].meta.title,
    description: dataSupport.default[lang].meta.description,
    canonical: dataSupport.default[lang].meta.canonical,
    image: dataSupport.default[lang].meta.image,
    meta: {
      charset: "utf-8",
      name: {
        keywords: dataSupport.default[lang].meta.keywords,
      },
    },
  });

  const hostName = "/Back-end/getDeviceById";

  useEffect(() => {
    setData(dataSupport.default[lang]);
    setMeta({
      title: dataSupport.default[lang].meta.title,
      description: dataSupport.default[lang].meta.description,
      canonical: dataSupport.default[lang].meta.canonical,
      keywords: dataSupport.default[lang].meta.keywords,
      image: dataSupport.default[lang].meta.image,
    });
  }, [lang]);

  const [versionPhysio, setVersionPhysio] = useState({
    model: "PHYSIO",
    ihm: "",
    core: ""
  }
  );
  
  

  const versionHostName = "/Back-end/getVersion";
  const loadPhysioData = () => {
      console.log(versionPhysio.model);
      axios
          .get(
              `${versionHostName}/PHYSIO`
          )
          .then((reponse) => {
              console.log(reponse.data)
              setVersionPhysio(reponse.data);
          });
  }

  useEffect(() => { loadPhysioData() }, []);

  const loadData = () => {
    const devID = JSON.parse(sessionStorage.getItem("deviceID"));
    axios
      .get(
        `${hostName}/${devID}`
      )
      .then((reponse) => {
        setDevice(reponse.data)
        console.log(reponse.data)
        console.log(reponse.data.interface)
        console.log(versionPhysio)
        if(reponse.data.interface !== versionPhysio.ihm)
        {
          document.getElementById('ihmInfo').classList.add('hidden');
        }
        if(reponse.data.core !== versionPhysio.core)
        {
          document.getElementById('coreInfo').classList.add('hidden');
        }
      });
  }

  useEffect(() => { loadData() }, []);

  // changement de page
  return (
    <>
      <Meta meta={meta} />
      <HeaderAccount />
      <Breadcrumb title={data.breadcrumbPHYSIO} />

      <SideBar >
        <section className="technicalSupport">
          <div className="toolbox"> {/*device informations*/}
            <BigTitle
              text=""
              title=""
              span={data.device.title}
              direct="left"
            />
            <p className='pinfo'>{data.device.numSerial} {device.serial_num}</p>
            <hr />
            <p className='pinfo'>{data.device.codeAct} {device.code}</p>
            <hr />
          </div>

          <div className="toolbox"> {/*version informations*/}
            <BigTitle
              text=""
              title=""
              span={data.information.title}
              direct="left"
            />
            <p className='pinfo'>{data.information.interface} {device.interface} <span id="ihmInfo" className="notif">{data.information.updateInfo}</span></p>
            <hr />
            <p className='pinfo'>{data.information.core} {device.core} <span id="coreInfo" className="notif">{data.information.updateInfo}</span></p>
            <hr />
          </div>

          <div className="toolbox">  {/*documentation*/}
            <BigTitle
              text=""
              title=""
              span={data.download.title}
              direct="left"
            />


            <p className='pinfo'>{data.download.userguide}</p>
            <p className='pinfo'>Français   <a
              className="center"
              href={`https://mad-up.fr/guide/guide_utilisateur_PHYSIO.pdf?download`}
              target="_blank"
              download="guide utilisateur MAD-UP PHYSIO.pdf"
            >
              <IconButton aria-label="download">
                <i className="fa-solid fa-download"></i>
              </IconButton>
            </a></p>
            <hr />
            <p className='pinfo'>English    <a
              className="center"
              href={`https://mad-up.fr/guide/user_guide_PHYSIO.pdf?download`}
              target="_blank"
              download="MAD-UP PHYSIO user guide.pdf"
            >
              <IconButton aria-label="download">
                <i className="fa-solid fa-download"></i>
              </IconButton>
            </a></p>
            <hr />
          </div>


        </section>
      </SideBar>

      <Footer />
    </>
  );
};

export default TechnicalSupport;
