import React,{useState, useEffect} from 'react';
import { withRouter,Redirect, Link, useParams} from "react-router-dom";
import './GetDevice.scss';
import axios from 'axios';
import * as dataDevice from "../../Data/MyDevice.json";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import ReactLoading from 'react-loading';
import { Formik, Field, Form } from 'formik';

export const GetDevice = (props) => {
 
  const[device, setDevice]=useState(null);
  const {lang} = useParams();

  let auth=[];
  auth=JSON.parse(sessionStorage.getItem('userData'))

  const [versionPro, setVersionPro] = useState({
    model: "PRO",
    ihm: "",
    core: ""
  }
  );
  const [versionPhysio, setVersionPhysio] = useState({
    model: "PHYSIO",
    ihm: "",
    core: ""
  }
  );

  const versionHostName = "/Back-end/getVersion";
  const loadProData = () => {
      console.log(versionPro.model);
      axios
          .get(
              `${versionHostName}/PRO`
          )
          .then((reponse) => {
              setVersionPro(reponse.data);
              console.log(reponse.data)
          });
  }
  const loadPhysioData = () => {
      console.log(versionPhysio.model);
      axios
          .get(
              `${versionHostName}/PHYSIO`
          )
          .then((reponse) => {
              setVersionPhysio(reponse.data);
              console.log(reponse.data)
          }).catch(error=>{
            console.log(error)
          });
  }

  useEffect(() => { loadProData() }, []);
  useEffect(() => { loadPhysioData() }, []);

  const upToDateHostName="/Back-end/isUpToDate";
  let upToDate = true;

  const user_id=auth.user_id;
  const hostName="/Back-end/getDevice";
  useEffect(() => {
    axios
      .get(
        `${hostName}/${user_id}`
      )
      .then((reponse) => {
        console.log(reponse.data)
        setDevice(reponse.data);
      }).catch(error=>{
        console.log(error)
      });
  }, []);

      return (
        <>
          {(device)?device.map((device) => {
            
            if(device.name === "PHYSIO") {
              if(versionPhysio.ihm === device.interface & versionPhysio.core === device.core)
                upToDate = true;
              else
                upToDate = false;
              return (
                <Device
                  props={props}
                  lang={lang}
                  key={device.dev_id}
                  name={device.name}
                  dev_id={device.dev_id}
                  serial_num={device.serial_num} 
                  code={device.code}
                  lastName={auth.last_name} 
                  firstName={auth.first_name}
                  upToDate={upToDate}
                  />
              )
            }
            if(device.name === "PRO") {
              return (
                <DevicePro
                  props={props}
                  lang={lang}
                  key={device.dev_id}
                  dev_id={device.dev_id}
                  name={device.name} 
                  serial_num={device.serial_num} 
                  code={device.code}
                  lastName={auth.last_name} 
                  firstName={auth.first_name}
                  />
              )
            }
          })
              : <ReactLoading type="bars" color="#15acf2" height={667} width={375} /> 
            }
          </>
    
      )
    };

    const Device=({props, name, code, serial_num, dev_id, firstName, lastName, upToDate})=>{
      const [basicModal, setBasicModal] = useState(false);
      const toggleShow = () => setBasicModal(!basicModal);
      const {lang} = useParams();
      const [data, setData] = useState(dataDevice.default[lang]);

      const goToolBox = () => {
        sessionStorage.setItem('deviceID',JSON.stringify(dev_id))
        window.location.href=`/${props.lang}/ToolboxPhysio`
      }
       
      const hostName="/Back-end/RequestdeleteDevice";
      const handleSubmit = (dataDevice) => {
        axios.post(hostName,dataDevice).then((response)=>{
          window.location.reload(false)
          console.log(response.data.successSend)
        }).catch(error=>{
          console.log(error)
        })
      };

      if(upToDate)
      {
        return(
          <section className='displayDevice'>
            <div class="imgDev">
              <img src="https://mad-up.com/api/uploads/mad-up/originals/imgDispoPhysio.png" alt="Device" />
            </div>
            <div className='content' >
              <p className='pinfo'>{name}</p>
              <hr/>
              <p  className='pinfo'>{data.device.numSerial} {serial_num}</p>
              <hr/>
              <p  className='pinfo'>{data.device.codeAct} {code}</p>
              <hr/>
              <p  className='pinfo'>
                <div className="buttonExtended color" onClick={goToolBox}>
                  <span className="hyphen"></span>
                  <div className="buttonExtended_text ligne-texte-icone">
                    <span class="texte">{data.device.info}</span>
                  </div>
                </div>
              </p>
              <hr/>
            </div>
            <div class="imgTrash">
              <i className="fa-regular fa-2x fa-trash-can"  onClick={toggleShow}></i>
            </div>
          
            <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
              <MDBModalDialog>
                <MDBModalContent>
                  <MDBModalHeader style={{border:"none"}}>
                    <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                  </MDBModalHeader>
                  <Formik
                    initialValues={{
                      value: "oui",
                      id:dev_id,
                      name:name,
                      lastName:lastName,
                      firstName:firstName,
                      sn:serial_num
                    }}
                    onSubmit={(values) => { handleSubmit(values); }}
                  >
                  {({ values }) => (
                    <Form>
                      <MDBModalBody style={{textAlign:"center"}}>{data.device.msg}</MDBModalBody>
                      <MDBModalFooter style={{border:"none"}}>
                        <button type="button" style={{background:"#DC3535", border:"none", width:"100px", color:"#fff", padding:"8px", borderRadius:"10px", fontWeight:"bold", textAlign:"center"}} onClick={toggleShow}>
                        {data.device.close}
                        </button>
                        <button style={{background:"#15acf2", border:"none", width:"100px", color:"#fff", padding:"8px", borderRadius:"10px", fontWeight:"bold"}} type="submit" >{data.device.reponse}</button>
                        <p className='msgRepon'></p>
                      </MDBModalFooter>
                    </Form>
                  )}
                  </Formik>
                </MDBModalContent>
              </MDBModalDialog>
            </MDBModal>
          </section>
          )
      }
      else{
        return(
      <section className='displayDevice'>
        <div class="imgDev">
          <img src="https://mad-up.com/api/uploads/mad-up/originals/imgDispoPhysio.png" alt="Device" />
        </div>
        <div className='content' >
          <p className='pinfo'>{name}</p>
          <hr/>
          <p  className='pinfo'>{data.device.numSerial} {serial_num}</p>
          <hr/>
          <p  className='pinfo'>{data.device.codeAct} {code}</p>
          <hr/>
          <p  className='pinfo'>
            <div className="buttonExtended color" onClick={goToolBox}>
              <span className="hyphen"></span>
              <div className="buttonExtended_text ligne-texte-icone">
                <span class="texte">{data.device.info}</span>
                <span id="icone" class="icone" >
                  <i class="fa fa-2x fa-bell imgTrash squizz"></i>
                </span>
              </div>
            </div>
          </p>
          <hr/>
        </div>
        <div class="imgTrash">
          <i className="fa-regular fa-2x fa-trash-can"  onClick={toggleShow}></i>
        </div>
      
        <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
          <MDBModalDialog>
            <MDBModalContent>
              <MDBModalHeader style={{border:"none"}}>
                <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
              </MDBModalHeader>
              <Formik
                initialValues={{
                  value: "oui",
                  id:dev_id,
                  name:name,
                  lastName:lastName,
                  firstName:firstName,
                  sn:serial_num
                }}
                onSubmit={(values) => { handleSubmit(values); }}
              >
              {({ values }) => (
                <Form>
                  <MDBModalBody style={{textAlign:"center"}}>{data.device.msg}</MDBModalBody>
                  <MDBModalFooter style={{border:"none"}}>
                    <button type="button" style={{background:"#DC3535", border:"none", width:"100px", color:"#fff", padding:"8px", borderRadius:"10px", fontWeight:"bold", textAlign:"center"}} onClick={toggleShow}>
                    {data.device.close}
                    </button>
                    <button style={{background:"#15acf2", border:"none", width:"100px", color:"#fff", padding:"8px", borderRadius:"10px", fontWeight:"bold"}} type="submit" >{data.device.reponse}</button>
                    <p className='msgRepon'></p>
                  </MDBModalFooter>
                </Form>
              )}
              </Formik>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </section>
      )
      }
      
    }

    const DevicePro=({props, name, code, serial_num, dev_id, firstName, lastName})=>{
      const [basicModal, setBasicModal] = useState(false);
      const toggleShow = () => setBasicModal(!basicModal);
      const {lang} = useParams();
      const [data, setData] = useState(dataDevice.default[lang]);
      
      const goToolBox = () => {window.location.href=`/${props.lang}/ToolboxPro`}

      const hostName="/Back-end/RequestdeleteDevice";
      const handleSubmit = (dataDevice) => {
        axios.post(hostName,dataDevice).then((response)=>{
          window.location.reload(false)
          console.log(response)
        }).catch(error=>{
          console.log(error)
        })
      };

      // const upToDateHostName="/Back-end/isUpToDate";
      // const loadData = () => {
      //   axios
      //       .get(
      //           `${upToDateHostName}/${serial_num}`
      //       )
      //       .then((reponse) => {
      //         console.log(reponse.data)
      //         if(reponse.data.status)
      //         {
      //           document.getElementById('icone').classList.add('hidden');
      //         }
      //       });
      // }

      // useEffect(() => { loadData() }, []);

      return(
      <section className='displayDevice'>
        <div class="imgDev">
          <img src="https://mad-up.com/api/uploads/mad-up/originals/imgDispo.png" alt="Device" />
        </div>
        <div className='content' >
          <p className='pinfo'>{name}</p>
          <hr/>
          <p  className='pinfo'>{data.device.numSerial} {serial_num}</p>
          <hr/>
          <p  className='pinfo'>{data.device.codeAct} {code}</p>
          <hr/>
          <p  className='pinfo'>
            <div className="buttonExtended color" onClick={goToolBox}>
              <span className="hyphen"></span>
              <div className="buttonExtended_text ligne-texte-icone">
                <span class="texte">{data.device.info}</span>
                {/* <span class="icone">
                  <i class="fa fa-2x fa-bell imgTrash squizz"></i>
                </span> */}
              </div>
            </div>
          </p>
          <hr/>
        </div>
        <div class="imgTrash">
          <i className="fa-regular fa-2x fa-trash-can"  onClick={toggleShow}></i>
        </div>
      
        <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
          <MDBModalDialog>
            <MDBModalContent>
              <MDBModalHeader style={{border:"none"}}>
                <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
              </MDBModalHeader>
              <Formik
                initialValues={{
                  value: "oui",
                  id:dev_id,
                  name:name,
                  lastName:lastName,
                  firstName:firstName,
                  serial_num:serial_num
                }}
                onSubmit={(values) => { handleSubmit(values); }}
              >
              {({ values }) => (
                <Form>
                  <MDBModalBody style={{textAlign:"center"}}>{data.device.msg}</MDBModalBody>
                  <MDBModalFooter style={{border:"none"}}>
                    <button type="button" style={{background:"#DC3535", border:"none", width:"100px", color:"#fff", padding:"8px", borderRadius:"10px", fontWeight:"bold", textAlign:"center"}} onClick={toggleShow}>
                    {data.device.close}
                    </button>
                    <button style={{background:"#15acf2", border:"none", width:"100px", color:"#fff", padding:"8px", borderRadius:"10px", fontWeight:"bold"}} type="submit" >{data.device.reponse}</button>
                    <p className='msgRepon'></p>
                  </MDBModalFooter>
                </Form>
              )}
              </Formik>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </section>
      )
    }

export default GetDevice;
