import React, { useState, useEffect } from 'react';
import { Navigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import Breadcrumb from '../../Components/Breadcrumb';
import * as dataJSON from "../../Data/Version.json";
import Meta from "../../Components/Meta";
import { useFormik } from "formik";
import axios from 'axios';
import "./Version.scss";
import HeaderAccount from "../../Components/HeaderAccount/HeaderAccount";
import SideBar from '../../Components/SideBar/SideBar';
import Footer from '../../Components/Footer'
import BigTitle from "../../Components/BigTitle";

export const Version = (props) => {

    const { lang } = useParams();
    const [data, setData] = useState(dataJSON.default[lang]);
    const [meta, setMeta] = useState({
        title: dataJSON.default[lang].meta.title,
        description: dataJSON.default[lang].meta.description,
        canonical: dataJSON.default[lang].meta.canonical,
        image: dataJSON.default[lang].meta.image,
        meta: {
            charset: "utf-8",
            name: {
                keywords: dataJSON.default[lang].meta.keywords,
            }

        },
    });

    const [versionPro, setVersionPro] = useState({
        model: "PRO",
        ihm: "",
        core: ""
    }
    );
    const [versionPhysio, setVersionPhysio] = useState({
        model: "PHYSIO",
        ihm: "",
        core: ""
    }
    );

    useEffect(() => {
        setData(dataJSON.default[lang]);
        setMeta({
            title: dataJSON.default[lang].meta.title,
            description: dataJSON.default[lang].meta.description,
            canonical: dataJSON.default[lang].meta.canonical,
            keywords: dataJSON.default[lang].meta.keywords,
            image: dataJSON.default[lang].meta.image
        });
    }, [lang]);

    const searchDeviceHostname = "/Back-end/getMailNotificationUpdate";
    const mailHostname = "/Back-end/sendNotificationUpdate";

    const notification = {
        sendUpdateNotification(updateInfo, data) {
            console.log(updateInfo)
            axios.post(searchDeviceHostname, updateInfo).then((response) => {
                console.log(response.data)
                response.data.forEach(device => {
                    console.log(device.email)
                    
                    const params = {
                        email: device.email,
                        sn: device.serial_num,
                        name: device.first_name,
                        model: device.name,
                        subject: data.mail.subject,
                        wellcome: data.mail.wellcome,
                        msgStart: data.mail.msgStart,
                        msgSN: data.mail.msgSN,
                        msgEnd: data.mail.msgEnd,
                        details: data.mail.details,
                        msgLink: data.mail.msgLink,
                        sign: data.mail.sign,
                        msgIgnore: data.mail.msgIgnore
                    }
                    axios.post(mailHostname, params).then((response) => {
                    }).catch(error => {
                        console.log(error)
                    })
                    
                    window.location.reload(false)
                });
            }).catch(error => {
                console.log(error)
            })
        }
    }

    const hostName = "/Back-end/getVersion";
    const loadProData = () => {
        console.log(versionPro.model);
        axios
            .get(
                `${hostName}/PRO`
            )
            .then((reponse) => {
                setVersionPro(reponse.data);
                console.log(reponse.data)
            });
    }
    const loadPhysioData = () => {
        console.log(versionPhysio.model);
        axios
            .get(
                `${hostName}/PHYSIO`
            )
            .then((reponse) => {
                setVersionPhysio(reponse.data);
                console.log(reponse.data)
            });
    }

    useEffect(() => { loadProData() }, []);
    useEffect(() => { loadPhysioData() }, []);

    return (
        <>
            <div className='formCreate'>
                <Meta meta={meta} />
                <HeaderAccount />
                <Breadcrumb title={data.breadcrumb} />
                <SideBar>
                    <VersionPhysio
                        props={data}
                        lang={lang}
                        devModel={versionPhysio.model}
                        devIhm={versionPhysio.ihm}
                        devCore={versionPhysio.core}
                        title={data.title.physio}
                        titleIhm={data.title.interface}
                        titleCore={data.title.core}
                        buttonSave={data.title.buttonSave}
                        notification={notification}
                    />

                    <VersionPro
                        props={data}
                        lang={lang}
                        devModel={versionPro.model}
                        devIhm={versionPro.ihm}
                        devCore={versionPro.core}
                        title={data.title.pro}
                        titleIhm={data.title.interface}
                        titleCore={data.title.core}
                        buttonSave={data.title.buttonSave}
                        notification={notification}
                    />
                </SideBar>

            </div>
            <Footer />
        </>
    )
};

const VersionPhysio = ({ props, title, devIhm, devCore, devModel, titleIhm, titleCore, buttonSave, notification }) => {
    const hostName = "/Back-end/updateSoftVersion";
    const handleSubmit = (dataVersion) => {
        axios.post(hostName, dataVersion).then((response) => {
            window.location.reload(false)
            notification.sendUpdateNotification(dataVersion, props)
        }).catch(error => {
            console.log(error)
        })
    };

    const mySchema = Yup.object().shape({
        ihm: Yup.string()
            .required("Ce champ est obligatoire !")
            .test('equal', 'identique à la valeur initiale', function (newValue) {
                let core = this.parent.core;
                if (newValue === devIhm && core === devCore)
                    return false;
                return true;
            }),
        core: Yup.string()
            .required("Ce champ est obligatoire !")
            .test('equal', 'identique à la valeur initiale', function (newValue) {
                let ihm = this.parent.ihm;
                if (newValue === devCore && ihm === devIhm)
                    return false;
                return true;
            }),
    });

    const formik = useFormik({
        initialValues: {
            model: devModel,
            ihm: devIhm,
            core: devCore
        },
        validationSchema: mySchema,
        onSubmit: (values) => {
            handleSubmit(values);
        },
        enableReinitialize: true,
    });

    return (
        <section className='displayVersion'>
            <BigTitle
                text=""
                title={title}
                small=""
                direct="left"
            />
            <img src="https://mad-up.com/api/uploads/mad-up/originals/imgDispoPhysio.png" alt="Device" />
            <form onSubmit={formik.handleSubmit}>
                <div className="form-group mb-3">
                    <label>
                        {titleIhm}
                    </label>

                    <input
                        type="text"
                        id="ihm"
                        name="ihm"
                        size="10"
                        value={formik.values.ihm}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </div>
                <div className="form-group mb-3">
                    <label htmlFor="core" >
                        {titleCore}
                    </label>

                    <input
                        type="text"
                        id="core"
                        name="core"
                        size="10"
                        value={formik.values.core}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </div>
                <div className="form-group d-flex justify-content-end gap-3">
                    <button type="submit" className="btn colorBtn" disabled={formik.isSubmitting || !formik.isValid}>
                        {buttonSave}
                    </button>
                </div>
            </form>
        </section>
    )
}

const VersionPro = ({ props, title, devIhm, devCore, devModel, titleIhm, titleCore, buttonSave, notification }) => {
    const hostName = "/Back-end/updateSoftVersion";
    const handleSubmit = (dataVersion) => {
        axios.post(hostName, dataVersion).then((response) => {
            window.location.reload(false)
            notification.sendUpdateNotification(dataVersion, props)
        }).catch(error => {
            console.log(error)
        })
    };

    const mySchema = Yup.object().shape({
        ihm: Yup.string()
            .required("Ce champ est obligatoire !")
            .test('equal', 'identique à la valeur initiale', function (newValue) {
                let core = this.parent.core;
                if (newValue === devIhm && core === devCore)
                    return false;
                return true;
            }),
        core: Yup.string()
            .required("Ce champ est obligatoire !")
            .test('equal', 'identique à la valeur initiale', function (newValue) {
                let ihm = this.parent.ihm;
                if (newValue === devCore && ihm === devIhm)
                    return false;
                return true;
            }),
    });

    const formik = useFormik({
        initialValues: {
            model: devModel,
            ihm: devIhm,
            core: devCore
        },
        validationSchema: mySchema,
        onSubmit: (values) => {
            handleSubmit(values);
        },
        enableReinitialize: true,
    });

    return (
        <section className='displayVersion'>
            <BigTitle
                text=""
                title={title}
                small=""
                direct="left"
            />
            <img src="https://mad-up.com/api/uploads/mad-up/originals/imgDispo.png" alt="Device" />
            <form onSubmit={formik.handleSubmit}>
                <div className="form-group mb-3">
                    <label>
                        {titleIhm}
                    </label>

                    <input
                        type="text"
                        id="ihm"
                        name="ihm"
                        size="10"
                        value={formik.values.ihm}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </div>
                <div className="form-group mb-3">
                    <label htmlFor="core" >
                        {titleCore}
                    </label>

                    <input
                        type="text"
                        id="core"
                        name="core"
                        size="10"
                        value={formik.values.core}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </div>
                <div className="form-group d-flex justify-content-end gap-3">
                    <button type="submit" className="btn colorBtn" disabled={formik.isSubmitting || !formik.isValid}>
                        {buttonSave}
                    </button>
                </div>
            </form>
        </section>
    )
}

export default Version